import { Link } from "gatsby"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Footer from "../../components/footer"
import Head from "../../components/header"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"
import "../common.css"

const AboutPage = () => (
  <Layout>
    <Head pageTitle="会社概要 - 株式会社GAZIRU" pageLang="ja" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
          <h4>会社概要</h4>
          <table>
            <tbody>
              <tr>
                <td>会社名</td>
                <td>株式会社GAZIRU
                  <br /> GAZIRU, Inc.
                </td>
              </tr>
              <tr>
                <td>事業概要</td>
                <td>画像による個体識別・種別認識機能の提供</td>
              </tr>
              <tr>
                <td>代表者</td>
                <td>福澤 茂和</td>
              </tr>
              <tr>
                <td>設立</td>
                <td>2020年 4月 1日</td>
              </tr>
              <tr>
                <td>アクセス</td>
                <td>〒158-0082<br />
                  東京都世田谷区等々力1-33-16
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />

          <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.9352222659395!2d139.64598425078728!3d35.60466444167773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f455af0fb3e3%3A0x3c73f307cfb4d9e!2z44CSMTU4LTAwODIg5p2x5Lqs6YO95LiW55Sw6LC35Yy6562J44CF5Yqb77yR5LiB55uu77yT77yT4oiS77yR77yW!5e0!3m2!1sja!2sjp!4v1657805129302!5m2!1sja!2sjp" width="100%" height="300" frameBorder="0"></iframe>

          <br />
          <br />
          <hr />
          <br />
          <br />
          <p>お問い合わせやお見積り依頼に関しては、こちらの<Link className="text-decoration-none mx-2" to="/notification/contact/ja">お問い合わせフォーム</Link>からお願いいたします。</p>
          <br />
          <br />
          <p><Link className="text-decoration-none" to="/">Home</Link></p>
        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default AboutPage
